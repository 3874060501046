const sendMail = () => {

    const form = document.querySelector('#registerForm');
    const modal = new bootstrap.Modal(document.getElementById('registerModal'));
    const submitFormBtn = document.getElementById('submitForm');
    const toastElement = document.getElementById('registration-success-toast');
    const toast = new bootstrap.Toast(toastElement);
    const toastBody = toastElement.querySelector('.toast-body');

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        submitFormBtn.setAttribute("disabled", "");
        const formData = new FormData(form);
        fetch('sendMail.php', {
            method: 'POST',
            body: formData
        })
        .then(res => res.text())
        .then(text => {
                toastBody.textContent = text;
                submitFormBtn.removeAttribute("disabled");
                form.reset()
                modal.hide()
                toast.show()
        })
    })


}

export default sendMail;